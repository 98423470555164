import * as React from "react";
import { Link, navigate } from "gatsby";
import slugify from "slugify";
import Layout from "../components/layout";
import Seo from "../components/seo";

const GlobalPage = ({ pageContext: { numPages, currentPage, data } }) => {
  const isFirst = currentPage === 1;
  const isLast = currentPage === numPages;
  const prevPage =
    currentPage - 1 === 1
      ? "/global/list"
      : `/global/list/${(currentPage - 1).toString()}`;
  const nextPage = `/global/list/${(currentPage + 1).toString()}`;
  const handlePageDropdown = (e) => {
    const page = e.target.value;
    const link = page === "1" ? "/global/list" : `/global/list/${page}`;
    navigate(link);
  };
  return (
    <Layout>
      <Seo title="รายชื่อ บลจ. ต่างประเทศ" />
      <div className="container mx-auto px-4 max-w-screen-sm">
        <section className="my-8">
          <h1 className="mx-4 my-2 text-gray-700 text-2xl">
            รายชื่อ บลจ. ต่างประเทศ <br className="sm:hidden" />
            ที่กองทุนไทยไปลงทุน
          </h1>

          <div
            className="bg-yellow-50 border-l-4 border-yellow-500 text-yellow-600 p-4 mb-5"
            role="alert"
          >
            <p className="font-bold">โปรดทราบ: หน้านี้อยู่ระหว่างทดสอบ</p>
            <p>
              ปัจจุบันยังอยู่ระหว่างการรวบรวมข้อมูลกองทุน ข้อมูลจึงยังไม่ครบถ้วน
            </p>
          </div>

          <div
            className="bg-white border border-gray-300 text-gray-600 rounded-md m-1"
            key={1}
          >
            <div className="divide-y divide-gray-300">
              {data.map((x) => {
                return (
                  <div key={x.fieldValue} className="hover:bg-gray-100">
                    <Link to={`/global/${slugify(x.fieldValue)}`}>
                      <div className="px-4 py-2">
                        <span className="text-gray-500 flex">
                          <span className="flex-auto">{x.fieldValue}</span>
                          <span className="flex-auto text-right">
                            {x.myCount} กองทุน
                          </span>
                        </span>
                      </div>
                    </Link>
                  </div>
                );
              })}
            </div>
          </div>
          <p className="text-gray-500 mt-4 mb-2 mx-4 text-center">
            <span>
              หน้า{" "}
              <select
                value={currentPage}
                onChange={handlePageDropdown}
                onBlur={handlePageDropdown}
              >
                {Array.from({ length: numPages }, (_, i) => (
                  <option key={i + 1} value={i + 1}>
                    {i + 1}
                  </option>
                ))}
              </select>{" "}
              / {numPages}
            </span>
          </p>
          <p className="text-gray-500 my-1 mx-4 text-center">
            {!isFirst && (
              <span className="mr-5">
                <Link to={prevPage} rel="prev">
                  ← หน้าก่อนหน้า
                </Link>
              </span>
            )}
            {!isLast && (
              <span>
                <Link to={nextPage} rel="next">
                  หน้าถัดไป →
                </Link>
              </span>
            )}
          </p>
        </section>
      </div>
    </Layout>
  );
};

export default GlobalPage;
